#arrow {
	width: 10rem;
	height: 10.5rem;
	border-radius: 10rem 0 0 10rem;
	position: fixed;
	right: 0;
	bottom: 34%;
	z-index: 1;
	transition: opacity .35s, visibility .35s;
	animation: .5s showNav forwards;
	background-color: var(--color-primary);
}
#nav-movilidad.open {
	z-index: 6;
}
#nav-movilidad .nav-content {
	border-radius: 10rem 0 0 10rem;
	right: 0;
	transform: translateX(100%);
}
#nav-movilidad .nav-content .close {
	right: 3.33%;
}
#nav-movilidad.open .nav-content {
	visibility: visible;
	transform: translate(0);
	transition-delay: 0s;
}
#nav-movilidad.open #arrow {
	opacity: 0;
	visibility: hidden;
}
#nav-movilidad.open + .close-nav {
	display: block;
	z-index: 5;
}

#movilidad .map {
	object-fit: cover;
}

@keyframes showNav {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}
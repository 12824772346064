.helping .camera {
	margin: 5rem 0 12.5rem;
}
.helping .camera .embed {
	width: 100%;
	aspect-ratio: 16 / 9;
}

.camera{
	display: flex;
	justify-content: center ;
	align-items: center;
}

iframe #ayuda {
	width: 1290px !important;
	height: 900px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 120px;
  }
  